import {
  track as ecTrack,
  useTracking as useEcTracking
} from '@toasttab/ec-track'

type DataToastTrackIdProps = {
  'data-toast-track-id': TrackId
}

type App = 'employee-benefits-spa'

type DashboardTrackId = 'dashboard'
type DashboardTrackIds =
  | 'group-health'
  | 'manual-deduction'
  | 'legacy'
  | 'vestwell'
  | 'payroll-pro-upsell'

export const trackDashboard = (
  actionTrackId: DashboardTrackIds
): DataToastTrackIdProps => {
  return track(`employee-benefits-spa.dashboard.${actionTrackId}`)
}

type ConsentTrackId = 'consent'
type ConsentTrackIds =
  | 'modal'
  | 'confirm'
  | 'privacy-statement'
  | 'simply-insured-email'
  | 'simply-insured-phone'
  | 'simply-insured-insurance-licenses'
  | 'toast-insurance-licenses'

export const trackConsent = (
  actionTrackId: ConsentTrackIds
): DataToastTrackIdProps => {
  return track(`employee-benefits-spa.consent.${actionTrackId}`)
}

type DeductionTrackId = 'deduction'
type DeductionTrackIds = 'close' | 'download' | 'chat'

export const trackDeduction = (
  actionTrackId: DeductionTrackIds
): DataToastTrackIdProps => {
  return track(`employee-benefits-spa.deduction.${actionTrackId}`)
}

type GroupHealthTrackId = 'group-health'
type GroupHealthTrackIds = 'new-plan-click' | 'broker-of-record-click'

export const trackGroupHealth = (
  actionTrackId: GroupHealthTrackIds
): DataToastTrackIdProps => {
  return track(`employee-benefits-spa.group-health.${actionTrackId}`)
}

type SimplyInsuredTrackId = 'simply-insured'
type SimplyInsuredTrackIds = 'request-close' | 'close' | 'cancel-close'

export const trackSimplyInsured = (
  actionTrackId: SimplyInsuredTrackIds
): DataToastTrackIdProps => {
  return track(`employee-benefits-spa.simply-insured.${actionTrackId}`)
}

export type TrackId =
  | `${App}.${DashboardTrackId}.${DashboardTrackIds}`
  | `${App}.${ConsentTrackId}.${ConsentTrackIds}`
  | `${App}.${DeductionTrackId}.${DeductionTrackIds}`
  | `${App}.${GroupHealthTrackId}.${GroupHealthTrackIds}`
  | `${App}.${SimplyInsuredTrackId}.${SimplyInsuredTrackIds}`

const track = (trackId: TrackId): { 'data-toast-track-id': TrackId } =>
  ecTrack(trackId) as DataToastTrackIdProps

export const useTracking = (): { track: (trackId: TrackId) => void } => {
  const { track: ecTrack } = useEcTracking()
  const track = (trackId: TrackId) => {
    ecTrack(trackId)
  }
  return { track }
}

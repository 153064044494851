import * as React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import cx from 'classnames'
import {
  FocusBody,
  FocusClose,
  FocusHeader,
  FocusView,
  HeadingGroup,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useCompanyCode } from '@toasttab/ec-session'
import { t } from '@local/translations'
import { useTracking } from '@local/track'
import { CloseConfirmation } from './CloseConfirmation'
import { InsuranceFlow } from '../../third-part-data-sharing-consent-modal'
import { useCustomer, useUser } from '@local/contexts'
import { NotAuthorizedState } from '@local/not-authorized-state'

/**
 * Opens the SimplyInsured experience via an iFrame inside a buffet FocusView component
 */
export const SimplyInsuredFocusView: React.FunctionComponent = () => {
  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
    React.useState<boolean>(false)
  const { track } = useTracking()

  const onCloseRequest = () => {
    track('employee-benefits-spa.simply-insured.request-close')
    setIsCloseConfirmationOpen(true)
  }
  const onCancelCloseRequest = () => setIsCloseConfirmationOpen(false)

  // this ensures we don't get two scroll bars, one in the iframe and one in the focus body
  const heightClass = 'h-[calc(100vh-156px)] sm:h-[calc(100vh-174px)]'

  const feinUuid = useLocation().state?.feinUuid
  const insuranceFlow = useLocation().state?.insuranceFlow
  const companyCode = useCompanyCode()
  const hasPermission = useUser().isPureUserHRPlusOrAbove
  const hasBenefitsBrokerageAdministration =
    useCustomer().hasBenefitsAdministration
  const hasEntitlementOverrideOption =
    useCustomer().hasEntitlementOverrideOption

  if (
    !hasPermission ||
    (!hasBenefitsBrokerageAdministration && !hasEntitlementOverrideOption)
  ) {
    return <NotAuthorizedState />
  }

  if (!['quote', 'broker-of-record', 'manage'].includes(insuranceFlow)) {
    return <Navigate to={`/${companyCode}/benefits`} replace />
  } else {
    const simplyInsuredUrl = getSimplyInsuredUrl(feinUuid, insuranceFlow)
    return (
      <>
        {isCloseConfirmationOpen && (
          <CloseConfirmation onCancel={onCancelCloseRequest} />
        )}
        <FocusView disableMaxWidth={true}>
          <FocusHeader>
            <HeadingGroup subTitle={t('simply-insured.subtitle')}>
              <Title>{t('simply-insured.title')}</Title>
            </HeadingGroup>
            <FocusClose onClick={onCloseRequest} />
          </FocusHeader>
          <FocusBody className={cx(heightClass, 'pb-4 sm:pb-6')}>
            <iframe
              className={cx(heightClass, 'w-full pb-4 sm:pb-6')}
              title={t('simply-insured.iframe.title')}
              src={simplyInsuredUrl.href}
            />
          </FocusBody>
        </FocusView>
      </>
    )
  }
}

const getSimplyInsuredUrl = (
  feinUuid: string | undefined | null,
  insuranceFlow: InsuranceFlow
): URL => {
  const environment = getCurrentEnvironment()

  const simplyInsuredUrl = new URL(
    environment === 'prod'
      ? 'https://www.simplyinsured.com/toast_launch_simplyinsured'
      : 'https://staging.simplyinsured.com/toast_launch_simplyinsured'
  )

  if (insuranceFlow === 'broker-of-record') {
    simplyInsuredUrl.searchParams.append('employer_flow', 'broker_of_record')
  }

  if (!!feinUuid) {
    simplyInsuredUrl.searchParams.append('fein_uuid', feinUuid)
  }

  return simplyInsuredUrl
}

import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const MultiSelectIcon = React.forwardRef(function MultiSelectIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M6.57 14.012a.75.75 0 10-1.14.976l1.14-.976zM9 18l-.57.488.624.727.556-.78L9 18zm5.61-6.564a.75.75 0 10-1.22-.872l1.22.872zM8 2.75h12v-1.5H8v1.5zM21.25 4v12h1.5V4h-1.5zM20 17.25h-2v1.5h2v-1.5zM6.75 6.5V4h-1.5v2.5h1.5zm14.5 9.5c0 .69-.56 1.25-1.25 1.25v1.5A2.75 2.75 0 0022.75 16h-1.5zM20 2.75c.69 0 1.25.56 1.25 1.25h1.5A2.75 2.75 0 0020 1.25v1.5zM8 1.25A2.75 2.75 0 005.25 4h1.5c0-.69.56-1.25 1.25-1.25v-1.5zm8 4H4v1.5h12v-1.5zm-12 0A2.756 2.756 0 001.25 8h1.5c0-.686.564-1.25 1.25-1.25v-1.5zM1.25 8v12h1.5V8h-1.5zm0 12A2.756 2.756 0 004 22.75v-1.5c-.686 0-1.25-.564-1.25-1.25h-1.5zM4 22.75h12v-1.5H4v1.5zm12 0A2.756 2.756 0 0018.75 20h-1.5c0 .686-.564 1.25-1.25 1.25v1.5zM18.75 20V8h-1.5v12h1.5zm0-12A2.756 2.756 0 0016 5.25v1.5c.686 0 1.25.564 1.25 1.25h1.5zM5.43 14.988l3 3.5 1.14-.976-3-3.5-1.14.976zm7.96-4.424l-5 7 1.22.872 5-7-1.22-.872z'
              fill='currentColor'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})

import * as React from 'react'
import { Cell, Row, Table } from '@toasttab/buffet-pui-table'
import { PayrollDashboardDisclosureCard } from '@toasttab/buffet-pui-payroll-dashboard-disclosure-card'
import { CommitmentAccountabilityIllustration } from '@toasttab/buffet-pui-illustrations'
import { PayrollFein } from '@local/grouter'
import { t } from '@local/translations'
import {
  ConsentModal,
  InsuranceFlow
} from '../../third-part-data-sharing-consent-modal'
import { trackGroupHealth } from '@local/track'
import { useNavigate } from 'react-router-dom'

export type FeinsWithoutBenefitsCardProps = {
  feins: Pick<PayrollFein, 'id' | 'name'>[]
  insuranceFlow?: InsuranceFlow
  showFeinTable: boolean
}
/**
 * FeinsWithoutBenefitsCard component
 */
export const FeinsWithoutBenefitsCard: React.FunctionComponent<
  FeinsWithoutBenefitsCardProps
> = ({ feins, insuranceFlow, showFeinTable }) => {
  const navigate = useNavigate()

  const openQuoteConsentModal = () => {
    trackGroupHealth('new-plan-click')
    navigate('quote')
  }
  const openBrokerOfRecordConsentModal = () => {
    trackGroupHealth('broker-of-record-click')
    navigate('broker-of-record')
  }
  const closeConsentModal = () => navigate('..', { relative: 'path' })

  return (
    <>
      {insuranceFlow && (
        <ConsentModal
          insuranceFlow={insuranceFlow}
          onRequestClose={closeConsentModal}
        />
      )}
      <PayrollDashboardDisclosureCard
        illustration={
          <CommitmentAccountabilityIllustration
            resizeToContainer={true}
            className='h-40'
          />
        }
        title={t('group-health.setup.card.title')}
        description={t('group-health.setup.card.description')}
        primaryAction={{
          label: t('group-health.setup.card.button.quote'),
          onClick: openQuoteConsentModal
        }}
        secondaryAction={{
          label: t('group-health.setup.card.button.connect'),
          onClick: openBrokerOfRecordConsentModal
        }}
        disclosureProps={{
          ariaLabelOpen: t(
            'group-health.setup.card.disclosure-aria-label-open'
          ),
          ariaLabelClose: t(
            'group-health.setup.card.disclosure-aria-label-close'
          ),
          iconLabel: feins.length.toString(),
          label: t('group-health.setup.card.disclosure-label')
        }}
      >
        {showFeinTable && (
          <Table variant='summary-card' className='text-secondary'>
            <tbody>
              {feins.map((fein) => {
                return (
                  <Row key={`fein-without-benefits-${fein.id}`}>
                    <Cell>{fein.name}</Cell>
                  </Row>
                )
              })}
            </tbody>
          </Table>
        )}
      </PayrollDashboardDisclosureCard>
    </>
  )
}

import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useCompanyCode } from '@toasttab/ec-session'
import { trackSimplyInsured, useTracking } from '@local/track'
import { t } from '@local/translations'

type CloseConfirmationProps = {
  onCancel: VoidFunction
}

export const CloseConfirmation: React.FunctionComponent<
  CloseConfirmationProps
> = ({ onCancel: onCancelFromProps }) => {
  const navigate = useNavigate()
  const companyCode = useCompanyCode()
  const returnUrl = useLocation().state?.returnUrl || `/${companyCode}/benefits`
  const onConfirm = () => {
    navigate(returnUrl)
  }

  const { track } = useTracking()
  const onCancel = () => {
    track('employee-benefits-spa.simply-insured.cancel-close')
    onCancelFromProps()
  }

  return (
    <Modal isOpen={true} onRequestClose={onCancel}>
      <Modal.Header>
        {t('simply-insured.close-confirmation.header')}
      </Modal.Header>
      <Modal.Body>{t('simply-insured.close-confirmation.body')}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} variant='link'>
          {t('simply-insured.close-confirmation.button.cancel')}
        </Button>
        <Button {...trackSimplyInsured('close')} onClick={onConfirm}>
          {t('simply-insured.close-confirmation.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

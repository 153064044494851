import * as React from 'react'
import { Cell, Row, Table } from '@toasttab/buffet-pui-table'
import { t } from '@local/translations'
import { FeinsWithBenefitsCardProps } from '../FeinsWithBenefitsCard'
import { ManageBenefitsButton } from './ManageBenefitsButton'
import { ConsentModal } from '../../../third-part-data-sharing-consent-modal'

export const FeinsWithBenefitsTable: React.FunctionComponent<
  FeinsWithBenefitsCardProps
> = ({ feins }) => {
  const [selectedFeinUuid, setSelectedFeinUuid] = React.useState<
    string | null | undefined
  >(undefined)

  return (
    <>
      {!!selectedFeinUuid && (
        <ConsentModal
          feinUuid={selectedFeinUuid}
          insuranceFlow='manage'
          onRequestClose={() => setSelectedFeinUuid(undefined)}
        />
      )}
      <Table variant='summary-card'>
        <tbody>
          {feins.map((fein) => {
            return (
              <Row key={`fein-without-benefits-${fein.id}`}>
                <Cell className='grid gap-y-2'>
                  <div className='font-semibold'>{fein.name}</div>
                  <div className='text-secondary'>
                    {t('group-health.benefits.fein', { fein: fein.fein })}
                  </div>
                </Cell>
                <Cell className='text-right'>
                  <ManageBenefitsButton
                    onClick={() => setSelectedFeinUuid(fein.id)}
                  />
                </Cell>
              </Row>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

import * as React from 'react'
import {
  HelpLinkGroup,
  PayrollHelpSection
} from '@toasttab/buffet-pui-payroll-help-section'
import { t, Trans } from '@local/translations'
import {
  DocumentIcon,
  HelpIcon,
  LaunchIcon,
  MultiSelectIcon
} from '@toasttab/buffet-pui-icons'
import { useCustomer, useUser } from '@local/contexts'
import { useHref } from 'react-router-dom'

export const HelpSection: React.FunctionComponent = () => {
  const client = useUser().client
  const affordableCareActDashboardLink = useHref(`/Company/ACA/Dashboard.aspx`)
  const affordableCareActCheckListLink = useHref(
    `/mvc/${client}/Company/ACA/Checklist`
  )
  const hasAcaModule = useCustomer().hasAcaModule

  const helpLinkGroups: HelpLinkGroup[] = [
    {
      label: t('group-health.help.large-employer-section'),
      helpLinks: [
        {
          icon: <HelpIcon accessibility='decorative' />,
          label: t('group-health.help.link.large-employer'),
          href: 'https://central.toasttab.com/s/article/Determining-Applicable-Large-Employer-Status-Under-the-ACA',
          newWindow: true
        },
        ...(hasAcaModule
          ? [
              {
                icon: <DocumentIcon accessibility='decorative' />,
                label: t('group-health.help.link.aca-reporting'),
                href: affordableCareActDashboardLink
              },
              {
                icon: <MultiSelectIcon accessibility='decorative' />,
                label: t('group-health.help.link.affordable-care-act'),
                href: affordableCareActCheckListLink
              }
            ]
          : [])
      ]
    },
    {
      label: t('group-health.help.more-section'),
      helpLinks: [
        {
          icon: <LaunchIcon accessibility='decorative' />,
          label: t('group-health.help.link.simply-insured-account'),
          href: 'https://www.simplyinsured.com/login',
          newWindow: true
        },
        {
          icon: <HelpIcon accessibility='decorative' />,
          label: t('group-health.help.link.affordable-care-act-faq'),
          href: 'https://central.toasttab.com/s/article/Toast-Pyroll-ACA-affordable-care-act-and-1095',
          newWindow: true
        }
      ]
    }
  ]

  return (
    <PayrollHelpSection
      className='mx-4 md:w-1/4 md:ml-6 md:mr-0'
      helpLinkGroups={helpLinkGroups}
      contactSupport={
        <Trans
          i18nKey='group-health.help.support.details'
          components={[
            <a
              className='inline-link'
              id='payroll_chatbot'
              key='chatbot-link'
              href='mailto:payrollsupport@toasttab.com' // fallback email in case javascript is not enabled
            >
              {t('group-health.help.support.details')}
            </a>
          ]}
        />
      }
    />
  )
}
